import React from 'react';

const NavIcons = props => {
    const icon = (props.icon).toLowerCase();
    const iconMap = {
        "appliances": Appliances,
        "auto": Auto,
        "dashboard": Dashboard,
        "events": Events,
        "meals": Meals,
        "medical": Medical,
        "miscellaneous": Miscellaneous,
        "tasks": Tasks,
        "documents": Documents,
        "docs": Documents,
        "": ""
    }
    var style = {
        width: props.width || 20,
        height: props.height || 20,
        style: props.fill || "white",
        spanStyle: props.spanStyle || {}
    }

    const Icon = iconMap[icon];
    return (<Icon {...style} />)
}


const Appliances = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.69238 40" height={props.height} width={props.width}>
                <title>
                    appliances
            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M20.0003,14.6031a10.9515,10.9515,0,1,1-10.952,10.951,10.9635,10.9635,0,0,1,10.952-10.951m-20,27.543a1.45979,1.45979,0,0,0,1.46,1.46h37.08a1.46043,1.46043,0,0,0,1.46-1.46V11.9221h-40Z" />
                        <path className="nav-icons" fill={props.style} d="M35.002,6.751a2.1805,2.1805,0,1,1,2.181-2.18,2.18,2.18,0,0,1-2.181,2.18m-8.2,0a2.1805,2.1805,0,1,1,2.181-2.18,2.18,2.18,0,0,1-2.181,2.18M38.54,0H1.461A1.46065,1.46065,0,0,0,0,1.46V9.002H40V1.46A1.4598,1.4598,0,0,0,38.54,0" />
                        <path className="nav-icons" fill={props.style} d="M19.9985,32.7477a7.46833,7.46833,0,0,0,7.458-7.328c-.944-.184-1.067-1.059-2.231-1.059-1.301,0-1.301,1.094-2.601,1.094-1.301,0-1.301-1.094-2.601-1.094-1.301,0-1.301,1.094-2.601,1.094-1.301,0-1.301-1.094-2.601-1.094-1.184,0-1.291.906-2.281,1.069a7.46833,7.46833,0,0,0,7.458,7.318" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Auto = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 31.345" height={props.height} width={props.width}>
                <title>
                    auto
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M40,16.945v7.491a.61621.61621,0,0,1-.145.436.77926.77926,0,0,1-.437.146H37.527v2.473a3.85657,3.85657,0,0,1-1.09,2.69,3.36629,3.36629,0,0,1-2.691,1.091,3.40325,3.40325,0,0,1-2.619-1.018,3.618,3.618,0,0,1-1.09-2.691V25.091h-20v2.472a3.86,3.86,0,0,1-1.091,2.691,3.62042,3.62042,0,0,1-2.691,1.091,3.61886,3.61886,0,0,1-2.691-1.091,3.61883,3.61883,0,0,1-1.091-2.691V25.091H.582a.61136.61136,0,0,1-.436-.146A.7786.7786,0,0,1,0,24.509V16.945a4.23255,4.23255,0,0,1,1.309-3.127,4.22941,4.22941,0,0,1,3.128-1.309h.581L7.055,4.291A5.11669,5.11669,0,0,1,9.091,1.236,5.84318,5.84318,0,0,1,12.582,0H27.564a5.53,5.53,0,0,1,3.491,1.236,5.46091,5.46091,0,0,1,2.036,3.055l2.036,8.218h.582a4.22941,4.22941,0,0,1,3.128,1.309A4.88616,4.88616,0,0,1,40,16.945m-3.127,1.891a2.76495,2.76495,0,0,0-.946-2.182,3.289,3.289,0,0,0-2.181-.945,2.87216,2.87216,0,0,0-2.182.945,3.1536,3.1536,0,0,0-.946,2.182,2.86972,2.86972,0,0,0,.946,2.182,3.15253,3.15253,0,0,0,2.182.945,2.86862,2.86862,0,0,0,2.181-.945,3.15363,3.15363,0,0,0,.946-2.182M10.109,12.581H29.964L28.218,5.6c0-.073-.145-.219-.291-.364a.62756.62756,0,0,0-.436-.218H12.509c-.145,0-.218.073-.436.218a.64465.64465,0,0,0-.291.364Zm-.727,6.255a2.76727,2.76727,0,0,0-.945-2.182,3.15254,3.15254,0,0,0-2.182-.945,2.87216,2.87216,0,0,0-2.182.945,3.29464,3.29464,0,0,0-.946,2.182,2.86972,2.86972,0,0,0,.946,2.182,3.29139,3.29139,0,0,0,2.182.945,2.87219,2.87219,0,0,0,2.182-.945,2.76731,2.76731,0,0,0,.945-2.182" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Dashboard = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 28.25582" height={props.height} width={props.width}>
                <title>
                    dashboard
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M39.95417,6.16364a1.44709,1.44709,0,0,0-.66585-.88713,37.89261,37.89261,0,0,0-38.57667,0A1.45029,1.45029,0,0,0,.20143,7.26156L9.79011,23.47594a1.44856,1.44856,0,0,0,1.98492.51049,14.1135,14.1135,0,0,1,1.86422-.91632L9.093,8.1a4.36091,4.36091,0,0,1,8.19441-2.95337l6.60336,15.869a5.97,5.97,0,0,1,1.33387,1.597,16.25048,16.25048,0,0,1,3.00043,1.37384A1.44927,1.44927,0,0,0,30.21,23.47594L39.79867,7.26156A1.44858,1.44858,0,0,0,39.95417,6.16364Z" />
                        <path className="nav-icons" fill={props.style} d="M21.168,23.22561l-6.98069-16.776a.998.998,0,0,0-1.87649.67312l5.27773,17.379a2.62946,2.62946,0,0,0-.24389,1.09791A2.6557,2.6557,0,1,0,21.168,23.22561Z" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Documents = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1935.55615 2334.04655" height={props.height} width={props.width}>
                <title>
                    documents
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M1909.26709,534.19092,1806.51758,431.44141,1504.11475,129.03882l-92.2334-92.2334L1375.07715.001,0,0V2334.04655H1935.55615V560.48Zm-125.5332,199.55371V2171.70817H151.81738v-2009.37H1212.32813v571.4065Z" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Events = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 39.2942" height={props.height} width={props.width}>
                <title>
                    events
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M9.7755,9.1638h.572a1.5763,1.5763,0,0,0,1.575-1.576V1.6168a1.5763,1.5763,0,0,0-1.575-1.576h-.572a1.5763,1.5763,0,0,0-1.575,1.576v5.971a1.5763,1.5763,0,0,0,1.575,1.576" />
                        <path className="nav-icons" fill={props.style} d="M29.9713,9.123h.572a1.57629,1.57629,0,0,0,1.575-1.576V1.576A1.57629,1.57629,0,0,0,30.5433,0h-.572a1.5763,1.5763,0,0,0-1.575,1.576V7.547a1.57629,1.57629,0,0,0,1.575,1.576" />
                        <path className="nav-icons" fill={props.style} d="M37.441,36.7352H2.559V13.3762H37.441Zm0-33.494H33.4v4.719c0,1.575-1.282,2.44-2.853,2.44h-.571a2.85514,2.85514,0,0,1-2.853-2.853V3.2412H13.2v4.347a2.85514,2.85514,0,0,1-2.853,2.853H9.775a2.85642,2.85642,0,0,1-2.853-2.853V3.2412H2.559A2.56323,2.56323,0,0,0,0,5.8002v30.935a2.56067,2.56067,0,0,0,2.559,2.559H37.441A2.56067,2.56067,0,0,0,40,36.7352V5.8002a2.56323,2.56323,0,0,0-2.559-2.559" />
                        <path className="nav-icons" fill={props.style} d="M21.3224,21.2126h4.596a.32948.32948,0,0,0,.331-.331v-3.979a.32949.32949,0,0,0-.331-.331h-4.596a.32949.32949,0,0,0-.331.331v3.979a.332.332,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M28.8248,21.2126h4.596a.32925.32925,0,0,0,.33-.331v-3.979a.32927.32927,0,0,0-.33-.331h-4.596a.32948.32948,0,0,0-.331.331v3.979a.332.332,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M6.3185,27.727h4.596a.32948.32948,0,0,0,.331-.331V23.417a.32948.32948,0,0,0-.331-.331H6.3185a.32948.32948,0,0,0-.331.331v3.979a.332.332,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M13.8204,27.727h4.596a.32948.32948,0,0,0,.331-.331V23.417a.32948.32948,0,0,0-.331-.331h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32948.32948,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M21.3224,27.727h4.596a.32948.32948,0,0,0,.331-.331V23.417a.32948.32948,0,0,0-.331-.331h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32948.32948,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M28.8248,27.727h4.596a.32926.32926,0,0,0,.33-.331V23.417a.32926.32926,0,0,0-.33-.331h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32948.32948,0,0,0,.331.331" />
                        <path className="nav-icons" fill={props.style} d="M10.9142,29.6045H6.3182a.32926.32926,0,0,0-.33.331v3.979a.32927.32927,0,0,0,.33.331h4.596a.32948.32948,0,0,0,.331-.331v-3.979a.32948.32948,0,0,0-.331-.331" />
                        <path className="nav-icons" fill={props.style} d="M18.4166,29.6045h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32949.32949,0,0,0,.331.331h4.596a.32949.32949,0,0,0,.331-.331v-3.979a.32948.32948,0,0,0-.331-.331" />
                        <path className="nav-icons" fill={props.style} d="M25.9186,29.6045h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32949.32949,0,0,0,.331.331h4.596a.32949.32949,0,0,0,.331-.331v-3.979a.32948.32948,0,0,0-.331-.331" />
                        <path className="nav-icons" fill={props.style} d="M33.4205,29.6045h-4.596a.32948.32948,0,0,0-.331.331v3.979a.32949.32949,0,0,0,.331.331h4.596a.32949.32949,0,0,0,.331-.331v-3.979a.332.332,0,0,0-.331-.331" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Meals = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.99948 39.81985" height={props.height} width={props.width}>
                <title>
                    meals
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M12.67348,24.87235c-.17.014-.34.022-.509.022a6.03639,6.03639,0,0,1-1.36-.17l-10.247,10.246a1.28738,1.28738,0,0,0,0,1.821l2.652,2.652a1.28678,1.28678,0,0,0,1.819,0l12.315-12.312-2.282-2.463Z" />
                        <path className="nav-icons" fill={props.style} d="M39.638,8.45675a1.22944,1.22944,0,0,0-1.74,0l-6.64,6.64a1.6069,1.6069,0,0,1-2.274-2.271l6.607-6.606a1.27986,1.27986,0,0,0-1.81-1.81l-6.605,6.605a1.60655,1.60655,0,1,1-2.273-2.271l6.639-6.639a1.23284,1.23284,0,0,0-1.743-1.744l-7.47,7.47a6.905,6.905,0,0,0-1.781,6.615l5.007,5.007a6.90289,6.90289,0,0,0,6.612-1.783l7.471-7.47a1.23125,1.23125,0,0,0,0-1.743" />
                        <path className="nav-icons" fill={props.style} d="M3.35569,1.03635a1.35506,1.35506,0,0,0-.958-.396,1.41284,1.41284,0,0,0-.225.018,1.35428,1.35428,0,0,0-.994.746,11.8024,11.8024,0,0,0,2.278,13.486l6.354,6.353a3.32271,3.32271,0,0,0,2.353.975c.094,0,.189-.004.283-.012l3.689-.314,16.086,17.354a1.35488,1.35488,0,0,0,.969.433h.023a1.36414,1.36414,0,0,0,.962-.396l2.755-2.756a1.358,1.358,0,0,0,.001-1.916Z" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Medical = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.99999 34.83899" height={props.height} width={props.width}>
                <title>
                    medical
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M40,10.615a12.46262,12.46262,0,0,1-2.3,6.805H29.464l-2.478-5.013a1.35923,1.35923,0,0,0-.569-.612,1.3135,1.3135,0,0,0-.815-.182,1.33143,1.33143,0,0,0-1.25,1.044L21.473,22.41,17.098,6.85a1.47416,1.47416,0,0,0-1.406-1.043,1.34789,1.34789,0,0,0-.871.306,1.38139,1.38139,0,0,0-.491.783L11.741,17.42H2.299A12.46079,12.46079,0,0,1,0,10.615,10.49653,10.49653,0,0,1,2.834,2.813,10.63822,10.63822,0,0,1,10.669,0a8.77349,8.77349,0,0,1,2.824.488,11.321,11.321,0,0,1,2.678,1.316q1.239.828,2.132,1.553A20.69243,20.69243,0,0,1,20,4.9a20.51971,20.51971,0,0,1,1.696-1.543q.89248-.726,2.132-1.553A11.33952,11.33952,0,0,1,26.506.488,8.77347,8.77347,0,0,1,29.33,0a10.63822,10.63822,0,0,1,7.835,2.813A10.49709,10.49709,0,0,1,40,10.615M28.571,20.323h6.808a2.25355,2.25355,0,0,1-.223.238c-.075.068-.142.125-.201.17l-.067.091L20.982,34.431a1.38694,1.38694,0,0,1-1.965,0L5.089,20.777a2.13474,2.13474,0,0,1-.469-.454h8.237a1.39066,1.39066,0,0,0,.881-.306,1.36458,1.36458,0,0,0,.503-.783l1.562-6.373,4.241,15.128a1.44565,1.44565,0,0,0,.514.749,1.38272,1.38272,0,0,0,.87.295,1.35019,1.35019,0,0,0,.848-.295,1.45142,1.45142,0,0,0,.514-.749l3.259-11,1.25,2.54a1.33578,1.33578,0,0,0,1.272.794" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Miscellaneous = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40" height={props.height} width={props.width}>
                <title>
                    miscellaneous
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M2.12159,0H7.88323a2.12159,2.12159,0,0,1,2.12159,2.12159V7.88323a2.12159,2.12159,0,0,1-2.12159,2.12159h-5.762A2.12119,2.12119,0,0,1,0,7.88363v-5.762A2.12159,2.12159,0,0,1,2.12159,0Z" />
                        <rect className="nav-icons" fill={props.style} x="14.99739" width="10.00522" height="10.00482" rx="2.12159" />
                        <path className="nav-icons" fill={props.style} d="M32.11677,0h5.762A2.12119,2.12119,0,0,1,40,2.12119v5.762a2.12159,2.12159,0,0,1-2.12159,2.12159H32.11677a2.12159,2.12159,0,0,1-2.12159-2.12159V2.12159A2.12159,2.12159,0,0,1,32.11677,0Z" />
                        <path className="nav-icons" fill={props.style} d="M2.12159,14.99759H7.88323a2.12159,2.12159,0,0,1,2.12159,2.12159v5.76164a2.12159,2.12159,0,0,1-2.12159,2.12159h-5.762A2.12119,2.12119,0,0,1,0,22.88122v-5.762A2.12159,2.12159,0,0,1,2.12159,14.99759Z" />
                        <rect className="nav-icons" fill={props.style} x="14.99739" y="14.99759" width="10.00522" height="10.00482" rx="2.12159" />
                        <path className="nav-icons" fill={props.style} d="M32.11677,14.99759h5.762A2.12119,2.12119,0,0,1,40,17.11878v5.762a2.12159,2.12159,0,0,1-2.12159,2.12159H32.11677a2.12159,2.12159,0,0,1-2.12159-2.12159V17.11918A2.12159,2.12159,0,0,1,32.11677,14.99759Z" />
                        <path className="nav-icons" fill={props.style} d="M2.12159,29.99518H7.88323a2.12159,2.12159,0,0,1,2.12159,2.12159v5.76164A2.12159,2.12159,0,0,1,7.88323,40h-5.762A2.12119,2.12119,0,0,1,0,37.87881v-5.762A2.12159,2.12159,0,0,1,2.12159,29.99518Z" />
                        <rect className="nav-icons" fill={props.style} x="14.99739" y="29.99518" width="10.00522" height="10.00482" rx="2.12159" />
                        <path className="nav-icons" fill={props.style} d="M32.11677,29.99518h5.762A2.12119,2.12119,0,0,1,40,32.11637v5.762A2.12159,2.12159,0,0,1,37.87841,40H32.11677a2.12159,2.12159,0,0,1-2.12159-2.12159V32.11677A2.12159,2.12159,0,0,1,32.11677,29.99518Z" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

const Tasks = props => {
    return (
        <span className="nav-icons" style={props.spanStyle}>
            <svg className="nav-icons" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.99999 40.00001" height={props.height} width={props.width}>
                <title>
                    tasks
	            </title>
                <g id="Layer_2" data-name="Layer 2">
                    <g id="Layer_1-2" data-name="Layer 1">
                        <path className="nav-icons" fill={props.style} d="M30.78544,5.73285a3.48565,3.48565,0,0,1,3.48171,3.4817v21.5709a3.48564,3.48564,0,0,1-3.48171,3.4817H9.21455a3.48564,3.48564,0,0,1-3.48171-3.4817V9.21455a3.48565,3.48565,0,0,1,3.48171-3.4817H30.78544m0-5.73285H9.21455A9.21457,9.21457,0,0,0,0,9.21455v21.5709A9.21457,9.21457,0,0,0,9.21455,40H30.78544A9.21457,9.21457,0,0,0,40,30.78545V9.21455A9.21457,9.21457,0,0,0,30.78544,0Z" />
                        <path className="nav-icons" fill={props.style} d="M17.133,29.1516A2.90764,2.90764,0,0,1,15.07714,28.3l-4.38752-4.38759a2.90764,2.90764,0,0,1,4.112-4.112l2.10505,2.10513,8.0888-9.98029a2.90764,2.90764,0,1,1,4.51779,3.66155L19.392,28.07478a2.90772,2.90772,0,0,1-2.10734,1.07285Q17.20873,29.15164,17.133,29.1516Z" />
                    </g>
                </g>
            </svg>
        </span>
    )
}

export default NavIcons;