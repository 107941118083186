import moment from 'moment';
import qs from 'qs';
import React, { Fragment } from 'react';
import { Checkbox } from 'react-bootstrap';
import {
  Button,
  Col,
  Glyphicon,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Panel,
  Row,
} from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { DashBoxHeader } from '../Pages/DashPage';
import { path, categoryColor } from '../../fetchHelpers';
import TaskForm, { priorityOptions } from '../Forms/TaskForm';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle, priority, completed) => {
  return ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    // border: `2px dashed ${priority.color}`,
    background: completed? 'none' : priority.color,
    border: completed? 'none' : `2px dashed ${priority.color.replace('.05', '.4')}`,
    // change background colour if dragging
    // background: isDragging ? 'lightgreen' : '#f2f5f8',
    color: completed ? 'grey' : 'black',
    textDecoration: completed ? 'line-through': 'none',
    // styles we need to apply on draggables
    ...draggableStyle
  });
}

const Task = ({
  index,
  onTaskClick,
  onTaskComplete,
  onTaskDeleteClick,
  onTaskEditClick,
  task: {
    completed, description, id, name,
  },
  priority,
}) => (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="task"
          onClick={onTaskClick}
          ref={provided.innerRef}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
            priority,
            completed,
          )}
        >

          {/* <div style={{ alignItems: 'center', display: 'flex' }}>
            <input
              checked={completed}
              onChange={onTaskComplete}
              style={{ marginLeft: 'calc(1em - 8px)', marginRight: '1em' }}
              type="checkbox"
            /> */}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Checkbox
              className="cb"
              style={{ marginRight: '1em', background: completed ? '#18bc9c' : 'white', border: '3px solid ', borderColor: completed ? '#18bc9c' : '#95a5a6' }}
              checked={completed}
              onChange={onTaskComplete}>
              <Glyphicon
                className="cb-check"
                glyph="ok"
                style={{ color: 'white', fontSize: completed ? '15px' : '0', transition: 'font-size .5s', marginLeft: '-18px', marginTop: '0' }}
              />
            </Checkbox>
            <div style={{ flexGrow: 1, alignItems: 'center' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between', fontFamily: "'Roboto'", alignItems: 'center' }}>
                <h4 style={{ fontFamily: 'Roboto' }}>{name}</h4>
                <div style={{ display: 'flex' }}>
                  <a href="#edit" onClick={onTaskEditClick} style={{ marginRight: '0.5em' }}>
                    <Glyphicon glyph="pencil" />
                  </a>
                  <a href="#remove" onClick={onTaskDeleteClick}>
                    <Glyphicon glyph="remove" />
                  </a>
                </div>
              </div>
              <p>{description}</p>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );

export class TaskList extends React.Component {
  render() {
    const { tasks } = this.props;

    return (
      <Fragment>
        {['HIGH', 'MID', 'LOW'].map((value, index) => {
          const priority = priorityOptions.find(op => op.value === value);

          if (!priority) return null;

          return (
            <Droppable droppableId={`tasks-${priority.label.toLowerCase()}`} key={index}>
              {provided => (
                <div {...provided.droppableProps} className="task-list" ref={provided.innerRef} style={{ padding: '0', margin: '1em', }}>
                  <h4 style={{ fontFamily: "'Raleway', sans-serif", fontWeight: '600', }}>{priority.label.toUpperCase()}</h4>
                  {tasks
                    .filter(task => task.priority === value)
                    .map(
                      (
                        {
                          onTaskClick,
                          onTaskComplete,
                          onTaskDeleteClick,
                          onTaskEditClick,
                          ...task
                        },
                        taskIndex,
                      ) => (
                          <Task
                            index={taskIndex}
                            key={taskIndex}
                            onTaskClick={onTaskClick}
                            onTaskComplete={onTaskComplete}
                            onTaskDeleteClick={onTaskDeleteClick}
                            onTaskEditClick={onTaskEditClick}
                            task={task}
                            priority={priority}
                          />
                        ),
                    )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        })}
      </Fragment>
    );
  }
}

class Tasks extends React.Component {
  state = {
    activeModal: null,
  };

  async componentDidMount() {
    const response = await fetch(
      `${path}/app/task?${qs.stringify({
        category: 'null',
        groupID: localStorage.getItem('selfGroupId'),
        pinned: 'null',
        userID: localStorage.getItem('userId'),
        weeksAhead: -1,
      })}`,
      {
        credentials: 'include',
      },
    );
    const { data: tasks } = await response.json();

    this.props.dispatch({
      type: 'REMOVE_ALL_TASKS',
    });
    this.props.dispatch({
      type: 'ADD_TASKS',
      payload: tasks.map(task => task.task),
    });
  }

  changeModal = value => this.setState({ activeModal: value });

  onDragEnd = async (result) => {
    const { tasks } = this.props;
    const { destination, draggableId, source } = result;

    // if Draggable dragged outside of DragDropContext
    if (!destination) {
      return;
    }

    // if same location
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const newPriority = priorityOptions.find(
      // droppableId something like 'tasks-medium'
      op => op.label.toLowerCase() === destination.droppableId.split('-')[1],
    );

    if (!newPriority) return;

    const task = Object.values(tasks)
      .reduce((prev, curr) => [...prev, ...curr], [])
      .find(tsk => tsk.itemID === draggableId);

    if (!task) return;

    await this.onTaskUpdate({
      ...task,
      deadline:  task.deadline ? moment(task.deadline).format('YYYY-MM-DD'): null,
      notification: task.notification ? moment(task.notification).format('YYYY-MM-DD') : null,
      priority: newPriority.value,
    });
  };

  onHideModal = () => this.changeModal(null);

  onTaskComplete = task => () => this.onTaskUpdate({
    ...task,
    completed: !task.completed,
    deadline: task.deadline ? moment(task.deadline).format('MMM d, YYYY') : null,
    notification: task.notification ? moment(task.notification).format('MMM d, YYYY') : null,
    taskID: task.itemID,
  });

  onTaskCreate = async (values) => {
    const response = await fetch(`${path}/app/task/add`, {
      body: JSON.stringify({
        group: {
          groupID: localStorage.getItem('selfGroupId'),
        },
        // task: values,
        task: {
          // deadline: values.deadline ? moment(values.deadline).format('MMM DD, YYYY') : null,
          // notification: values.notification ? moment(values.notification).format('MMM DD, YYYY') : null,
          // completed: values.completed ? 1 : 0,
          // pinned: values.pinned ? 1 : 0,
          // taskID: values.itemID,
          // ...values,
          deadline: values.deadline ? moment(values.deadline).format('MMM DD, YYYY') : null,
          notification: values.notification ? moment(values.notification).format('MMM DD, YYYY') : null,
          completed: values.completed ? 1 : 0,
          pinned: values.pinned ? 1 : 0,
          taskID: values.itemID,
          category: values.category,
          name: values.name,
          priority: values.priority,
          description: values.description,
        },
        user: {
          userID: localStorage.getItem('userId'),
        },
      }),
      credentials: 'include',
      method: 'POST',
    });
    const { data: task } = await response.json();

    this.props.dispatch({
      type: 'ADD_TASK',
      payload: task,
    });
    this.onHideModal();
  };

  onTaskCreateClick = () => this.changeModal('create-task');

  onTaskDelete = async ({ itemID, category }) => {
    await fetch(`${path}/app/task/delete`, {
      body: JSON.stringify({
        group: {
          groupID: localStorage.getItem('selfGroupId'),
        },
        task: {
          taskID: itemID,
        },
        user: {
          userID: localStorage.getItem('userId'),
        },
      }),
      credentials: 'include',
      method: 'POST',
    });

    this.props.dispatch({
      type: 'REMOVE_TASK',
      payload: {
        category,
        itemID,
      },
    });
  };

  onTaskDeleteClick = id => (event) => {
    event.preventDefault();
    // this.onTaskDelete(id);
    this.changeModal(`delete-${id}`);
  };

  onTaskDeleteConfirmClick = task => () => {
    this.changeModal(null);
    this.onTaskDelete(task);
  };

  onTaskEditClick = id => (event) => {
    event.preventDefault();
    this.changeModal(`edit-${id}`);
  };

  onTaskUpdate = async (values) => {
    const response = await fetch(`${path}/app/task/update`, {
      body: JSON.stringify({
        task: {
          deadline: values.deadline ? moment(values.deadline).format('MMM DD, YYYY') : null,
          notification: values.notification ? moment(values.notification).format('MMM DD, YYYY') : null,
          completed: values.completed ? 1 : 0,
          pinned: values.pinned ? 1 : 0,
          taskID: values.itemID,
          category: values.category,
          name: values.name,
          priority: values.priority,
          description: values.description,
        },
      }),
      credentials: 'include',
      method: 'POST',
    });

    const { data: task } = await response.json();

    this.props.dispatch({
      type: 'UPDATE_TASK',
      payload: task,
    });

    this.onHideModal();
  };

  render() {
    const { tasks } = this.props;
    const { activeModal } = this.state;
    let modalTask;

    if (activeModal && activeModal.includes('-')) {
      modalTask = Object.values(tasks)
        .reduce((prev, curr) => [...prev, ...curr], [])
        .find(task => task.itemID === Number(activeModal.split('-')[1]));
    }

    return (
      <div id="my-tasks">
        <Modal onHide={this.onHideModal} show={activeModal === 'create-task'}>
          <ModalHeader closeButton={true} onHide={this.onHideModal}>
            Create Task
          </ModalHeader>
          <ModalBody>
            <TaskForm onSubmit={this.onTaskCreate} />
          </ModalBody>
        </Modal>
        <Modal onHide={this.onHideModal} show={activeModal && activeModal.startsWith('edit')}>
          <ModalHeader closeButton={true} onHide={this.onHideModal}>
            Edit Task
          </ModalHeader>
          <ModalBody>
            <TaskForm
              isInitialValid={true}
              onSubmit={this.onTaskUpdate}
              task={activeModal && activeModal.startsWith('edit') && modalTask}
            />
          </ModalBody>
        </Modal>
        <Modal
          bsSize="small"
          onHide={this.onHideModal}
          show={activeModal && activeModal.startsWith('delete')}
        >
          <ModalHeader closeButton={true} onHide={this.onHideModal}>
            Are you sure you want to delete{' '}
            {activeModal && activeModal.startsWith('delete') && modalTask.name}?
          </ModalHeader>
          <ModalFooter>
            <Button bsSize="sm" onClick={this.onHideModal}>
              Close
            </Button>
            <Button
              bsSize="sm"
              bsStyle="danger"
              onClick={
                activeModal && activeModal.startsWith('delete')
                  ? this.onTaskDeleteConfirmClick(modalTask)
                  : undefined
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>
        <Row>
          <Col
            style={{ alignItems: 'flex-end', display: 'flex', justifyContent: 'space-between' }}
            xs={12}
          >
            <DashBoxHeader
              style={{ display: 'inline-flex', padding: '.5em', background: '#33363b', marginBottom: '1em', borderRadius: '5px' }}
              category={'tasks'}
              options={() => <Button bsStyle="success" style={{ borderRadius: '15px', margin: '.5em' }} onClick={this.onTaskCreateClick}><Glyphicon style={{ fontSize: '1.1em', paddingRight: '5px', paddingTop: '2px' }} glyph="plus-sign" /><b>CREATE TASK</b></Button>} />
          </Col>
        </Row>
        <div id="page-container">
          {Object.keys(tasks).map((value, index) => (
            <Panel key={index} style={{ minWidth: '400px', flex: '1 1', margin: '20px', height: '100%' }}>
              <Panel.Heading style={{ background: categoryColor[value], border: '0' }}>
                <Panel.Title componentClass="h3" style={{ color: 'white', fontWeight: '600', fontSize: '1.5em', fontFamily: 'Roboto, sans-serif' }}><b>{value.toLowerCase()}</b></Panel.Title>
              </Panel.Heading>
              <Panel.Body style={{ padding: '1em', background: 'white' }}>
                <DragDropContext onDragEnd={this.onDragEnd}>
                  <TaskList
                    tasks={tasks[value].map(task => ({
                      ...task,
                      id: task.itemID,
                      onTaskComplete: this.onTaskComplete(task),
                      onTaskDeleteClick: this.onTaskDeleteClick(task.itemID),
                      onTaskEditClick: this.onTaskEditClick(task.itemID),
                    }))}
                  />
                </DragDropContext>
              </Panel.Body>
            </Panel>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const obj = {};

  Object.entries(state.tasks).forEach(([key, value]) => {
    if (value) {
      obj[key] = value;
    }
  });

  return {
    tasks: obj,
  };
};

export default connect(mapStateToProps)(Tasks);
